import React from 'react';
import Modal from 'react-modal';
import AlertIcon from '../../assets/icon-alert-large.png';

Modal.setAppElement('#root');

interface AlertModalProps {
  title: string | null;
  content: string | null;
  isOpen: boolean;
  canClose?: boolean;
  closeModal?(event: (React.MouseEvent | React.KeyboardEvent)): void;
}

export const AlertModal: React.FC<AlertModalProps> = (props) => {
  const {
    title,
    content,
    isOpen,
    canClose = true,
    closeModal = () => null,
  } = props;

  return (
    <Modal
      shouldCloseOnEsc={canClose}
      shouldCloseOnOverlayClick={canClose}
      isOpen={isOpen}
      onRequestClose={closeModal}
    >
      <div className="modal">
        <div className="modal-icon">
          <img src={AlertIcon} alt="" className="icon-large" />
        </div>
        <div className="modal-text">
          <h1 className="h1 text-white">{title}</h1>
          <div className="spacer-5"></div>
          <div className="p text-white">{content}</div>
        </div>
      </div>
    </Modal>
  );
};

export default AlertModal;
