import React, { useState } from 'react';
import Lottie from 'react-lottie';
import DialText from '../DialText';

interface UnlockDialProps {
  animationData: any;
  hasAccess: boolean;
  accessTimeout: number;
  isAutomaticallyUnlocked: boolean;
  isRestricted?: boolean;
  isUnlocking: boolean;
  isAvailable: boolean;
  availability: string | undefined;
  header?: string;
  lockedSubtext?: string;
  textStyle?: any;
  subtextStyle?: any;
  onComplete?: () => void;
}

const UnlockDial: React.FC<UnlockDialProps> = ({
  animationData,
  hasAccess,
  accessTimeout,
  isAutomaticallyUnlocked,
  isRestricted,
  isUnlocking,
  isAvailable,
  availability,
  header,
  lockedSubtext,
  textStyle,
  subtextStyle,
  onComplete,
}) => {
  const [speed, setSpeed] = useState(1);
  const [isLocked, setIsLocked] = useState(true);

  const endUnlockingFrame = 28;
  const endLockingFrame = 261;

  let dialText = '';
  let dialSubtext = '';

  if (isLocked) {
    dialText = 'Locked';
    dialSubtext = lockedSubtext || '';
  }

  if (isUnlocking && !isLocked) {
    dialText = isLocked ? 'Locked' : 'Unlocked';
    dialSubtext = `Auto-locking in ${accessTimeout / 1000} seconds.`;
  }

  if (hasAccess && !isAvailable) {
    dialText = 'Closed';
    dialSubtext = availability || '';
  }

  if (isRestricted) {
    dialText = 'Restricted';
    dialSubtext = 'Permission Denied';
  }

  if (isAutomaticallyUnlocked) {
    dialText = 'Unlocked';
    dialSubtext = 'Access Granted';
  }

  return (
    <div className="unlock-dial-component">
      <DialText
        header={header}
        text={dialText}
        subtext={dialSubtext}
        textStyle={textStyle}
        subtextStyle={subtextStyle}
      />
      <Lottie
        style={{ overflow: 'visible' }}
        speed={speed}
        isStopped={!isUnlocking}
        isPaused={!isUnlocking}
        options={{
          animationData,
          autoplay: false,
          loop: false,
          rendererSettings: {
            viewBoxSize: '31 28 140 140',
          } as any, // @TODO The package types are missing viewBoxSize
        }}
        // @ts-ignore The package types are missing the animation argument for callback
        eventListeners={[
          {
            eventName: 'complete',
            callback: () => {
              if (onComplete) onComplete();
            },
          },
          {
            eventName: 'enterFrame',
            // @ts-ignore
            callback: (animation: any) => {
              if (animation.currentTime > endUnlockingFrame) {
                setSpeed(3961 / accessTimeout);
                setIsLocked(false);
              }

              if (animation.currentTime > endLockingFrame) {
                setSpeed(1);
                setIsLocked(true);
              }
            },
          },
        ]}
      />
    </div>
  );
};

export default UnlockDial;
