/* eslint-disable react-hooks/exhaustive-deps */
import { useLocalStorage } from '@rehooks/local-storage';
import React, { useEffect, useState } from 'react';
import { usegenerateAnonymousAccessTokenMutation } from '../../apollo';
import { getErrorMessage } from '../../helpers';
import { clearDeprecatedLocalStorage, useAnonymousUserId } from '../../hooks';
import AlertModal from '../AlertModal';
import { LoadingSpinner } from '../LoadingSpinner';

interface AnonymousRouteProps {
  children: React.ReactElement;
  policy: 'GET_TOKEN' | 'RENEW_TOKEN' | 'REQUIRE_TOKEN';
}

export const AnonymousRoute: React.FC<AnonymousRouteProps> = (props) => {
  const { children, policy } = props;

  const [anonymousToken, setAnonymousToken] = useLocalStorage<string>('chirpAccessToken');
  const [renewingToken, setRenewingToken] = useState(policy === 'RENEW_TOKEN');

  const anonymousUserId = useAnonymousUserId();
  const [generateAnonymousToken, { error }] = usegenerateAnonymousAccessTokenMutation();

  const generateAndStoreAnonymousToken = () => {
    generateAnonymousToken().then(({ data }) => {
      if (data) {
        setAnonymousToken(data.accessToken);
        setRenewingToken(false);
        clearDeprecatedLocalStorage(); // Clean up old values in local storage
      }
    });
  };

  useEffect(() => {
    if (policy === 'GET_TOKEN' && !anonymousToken) {
      generateAndStoreAnonymousToken();
    }
  }, [policy, anonymousToken]);

  useEffect(() => {
    if (policy === 'RENEW_TOKEN') {
      generateAndStoreAnonymousToken();
    }
  }, []); // Call once

  const hasAnonymousToken = !!anonymousToken && !!anonymousUserId;

  if (policy === 'REQUIRE_TOKEN' && !hasAnonymousToken) {
    return (
      <AlertModal
        isOpen
        title="Unauthorized"
        content="Please re-scan the QR code to access this page"
      />
    );
  }

  if (hasAnonymousToken && !renewingToken) {
    return children;
  }

  if (error) {
    return (
      <AlertModal
        isOpen
        title="Unexpected Error"
        content={getErrorMessage(error)}
      />
    );
  }

  return <LoadingSpinner />;
};

export default AnonymousRoute;
