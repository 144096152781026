import React from 'react';
import useWebShare from 'react-use-web-share';
import { useEulaModalState } from '../../hooks';
import CopyRequestAccessUrl from '../CopyRequestAccessUrl';
import { EulaModal } from '../EulaModal';
import SupportPhoneNumber from '../SupportPhoneNumber';

export interface RequestAccessButtonProps {
  accessPointName: string;
  isAllowed: boolean;
  propertyName: string;
  requestAccessUrl: string;
  supportPhoneNumber?: string | null;
  availability?: string;
  isVirtualDirectoryEnabled: boolean;
  onSuccess: () => void;
  onError: () => void;
}

const RequestAccessButton: React.FC<RequestAccessButtonProps> = ({
  accessPointName,
  isAllowed,
  propertyName,
  requestAccessUrl,
  supportPhoneNumber,
  availability,
  onSuccess,
  onError,
  isVirtualDirectoryEnabled,
}) => {
  const { loading, isSupported, share } = useWebShare(onSuccess, onError);
  const { acceptedEulaAt, isEulaModalOpen, openEulaModal, closeEulaModal } = useEulaModalState();

  const welcomeMessage = (
    <div className="content">
      <h2 className="h2 text-dark">Welcome to {propertyName}!</h2>
      <div className="p text-mid-gray">
        {isVirtualDirectoryEnabled
          ? 'Guests can request access from a resident by choosing one of the options below.'
          : 'Guests can request access from a resident by tapping the button below.'}
        {!isVirtualDirectoryEnabled &&
          <SupportPhoneNumber supportPhoneNumber={supportPhoneNumber} />
        }
      </div>
    </div>
  );

  if (!isAllowed) {
    return (
      <>
        {welcomeMessage}
        <button
          disabled
          className="blue-button w-inline-block"
        >
          <h1 className="h2" style={{ color: 'red' }}>Entrance Closed {availability && `(${availability})`}</h1>
        </button>
      </>
    );
  }

  if (!isSupported) {
    return (
      <>
        <CopyRequestAccessUrl
          hasAcceptedEula={Boolean(acceptedEulaAt)}
          openEulaModal={openEulaModal}
          propertyName={propertyName}
          supportPhoneNumber={supportPhoneNumber}
          requestAccessUrl={requestAccessUrl}
          isVirtualDirectoryEnabled={isVirtualDirectoryEnabled}
        />
        <EulaModal
          isOpen={isEulaModalOpen}
          closeModal={closeEulaModal}
        />
      </>
    );
  }

  const config = {
    title: 'Request Entry Access',
    // tslint:disable-next-line max-line-length
    text: `Hey, I'm at ${accessPointName}. Please approve my access request by tapping the link below:\n\n`,
    url: requestAccessUrl,
  };

  return (
    <>
      {welcomeMessage}
      <button
        className={`blue-button w-inline-block${loading ? 'disabled-button' : ''}`}
        style={{ borderBottom: '1px solid white' }}
        disabled={loading}
        onClick={() => {
          if (acceptedEulaAt) {
            share(config);
          } else {
            openEulaModal();
          }
        }}
      >
        <span
          className="display-flex-wrapper"
          style={{ alignItems: 'flex-start', textAlign: 'left' }}
        >
          <h1 className="h2">Contact Resident</h1>
          <span className="p text-white">
            Request access from a resident in your phone contacts
          </span>
        </span>
        <img src="/images/users.png" alt="" className="icon-20px" />
      </button>
      <EulaModal
        isOpen={isEulaModalOpen}
        closeModal={closeEulaModal}
        onAcceptEula={() => {
          share(config);
        }}
      />
    </>
  );
};

export default RequestAccessButton;
