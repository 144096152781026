import React from 'react';
import RequestAccessButton from '../RequestAccessButton';

interface AccessRequestOptionsProps {
  accessPointId: number | string;
  accessPointName: string;
  anonymousUserId: string;
  isAllowed: boolean;
  onShareSuccess: () => any;
  propertyName: string;
  supportPhoneNumber?: string | null;
  availability?: string;
  isVirtualDirectoryEnabled: boolean;
}

export const AccessRequestOptions: React.FC<AccessRequestOptionsProps> = (props) => {
  const {
    accessPointId,
    accessPointName,
    anonymousUserId,
    isAllowed,
    onShareSuccess,
    propertyName,
    supportPhoneNumber,
    availability,
    isVirtualDirectoryEnabled,
  } = props;

  // It would be better to use the deep link (chirpaccess://entry) for approval instead
  // of the universal link, but old versions of the access app don't support this.
  //
  // @TODO: Set up the mobile app to use the staging deep link.
  const universalLink = 'https://access.chirpsystems.com';
  const requestAccessUrl = `${universalLink}/entry/${accessPointId}?a=${anonymousUserId}`;

  return (
    <div>
      <RequestAccessButton
        availability={availability}
        isAllowed={isAllowed}
        propertyName={propertyName}
        supportPhoneNumber={supportPhoneNumber}
        requestAccessUrl={requestAccessUrl}
        accessPointName={accessPointName}
        onSuccess={onShareSuccess}
        isVirtualDirectoryEnabled={isVirtualDirectoryEnabled}
        // @ts-ignore
        onError={(shareError: Error) => {
          // @TODO: Handle errors
          /* tslint:disable-next-line no-console */
          console.error(shareError);
        }}
      />
    </div>
  );
};

export default AccessRequestOptions;
