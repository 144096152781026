import { useLocalStorage } from '@rehooks/local-storage';
import jwtDecode from 'jwt-decode';

interface AnonymousTokenPaylod {
  anonymousUserId?: string;
  vdExp?: number;
}

export function useAnonymousTokenPayload(): AnonymousTokenPaylod | null {
  const [accessToken] = useLocalStorage<string>('chirpAccessToken');

  try {
    return accessToken ? jwtDecode<any>(String(accessToken)) : null;
  } catch {
    return null;
  }
}

export default useAnonymousTokenPayload;
