import { TimetableRuleState } from '@chirp/access-verification';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AccessPointFragment, usegetAccessPointQuery } from '../../apollo';
import {
  getAvailabilityDescription,
  getAvailabilityState,
  getErrorMessage,
} from '../../helpers';
import AlertModal from '../AlertModal';
import TapToUnlockDial from '../TapToUnlockDial';

interface TapToUnlockDrawerProps {
  accessPointId: string;
  expiresAt: string;
  onClose: () => void;
  onFetchedAccessPoint: (accessPoint: AccessPointFragment) => void;
}

export const TapToUnlockDrawer: React.FC<TapToUnlockDrawerProps> = (props) => {
  const navigate = useNavigate();

  const [availability, setAvailability] = useState('');
  const [hasAccess, setHasAccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [animated, setAnimated] = useState(false);
  const { accessPointId, expiresAt, onClose, onFetchedAccessPoint } = props;

  const { loading, data } = usegetAccessPointQuery({
    skip: !accessPointId,
    variables: { accessPointId },
    onCompleted: async ({ accessPoint: loadedAccessPoint }) => {
      try {
        const { timetable } = loadedAccessPoint;
        const { timezone } = loadedAccessPoint.property;

        const accessTime = new Date();

        if (!moment(accessTime).isBefore(expiresAt)) {
          // Simulate API error when access is expired
          throw new Error('You do not have access to this entry point');
        }

        onFetchedAccessPoint(loadedAccessPoint);
        setHasAccess(true);
        setAvailability(getAvailabilityDescription(accessTime, timetable, timezone));
      } catch (error) {
        if (
          accessPointId &&
          getErrorMessage(error as any) === 'You do not have access to this entry point'
        ) {
          // Access has likely expired if the user receives this error message.
          // Let's redirect them to the callbox killer page to request new access.
          navigate(`/qr/${accessPointId}`);
        } else {
          setHasAccess(false);
          setErrorMessage(getErrorMessage(error as any, 'Unable to unlock entry point'));
        }
      }
    },
    onError: (error) => {
      setErrorMessage(getErrorMessage(error, 'Unable to unlock entry point'));
    },
  });

  const accessPoint = data ? data.accessPoint : null;

  useEffect(() => {
    setTimeout(() => {
      setAnimated(true);
    }, 200);
  }, []);

  if (errorMessage) {
    return (
      <AlertModal
        isOpen
        title="Unable to unlock entry point"
        content={errorMessage}
        closeModal={() => {
          setErrorMessage(null);
          onClose();
        }}
      />
    );
  }

  if (loading) {
    return null;
  }

  if (accessPoint) {
    const { name, property } = accessPoint;
    const { timetable } = accessPoint;
    const { timezone } = property;

    const accessTimeout = accessPoint.accessTimeout || 5000;
    const availabilityState = getAvailabilityState(new Date(), timetable, timezone);

    return (
      <div className={`${animated ? 'drawer-animated' : ''} drawer-wrapper`}>
        <div className="drawer">
          <div className="access-point-name">{name}</div>
          <TapToUnlockDial
            accessPointId={accessPointId}
            accessTimeout={accessTimeout}
            isAllowed={hasAccess}
            isAutomaticallyUnlocked={availabilityState === TimetableRuleState.UNLOCKED}
            availability={availability}
          />
        </div>
      </div>
    );
  }

  return null;
};

export default TapToUnlockDrawer;
